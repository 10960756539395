<template>
    <div>
        redirecting...
    </div>
</template>

<script>
import { AlertTypeLine } from "@/utils/alertType"

export default {
    name: "LineNotifyRedirect",
    mounted() {
        this.addNotificationToUser();
    },
    methods: {
        async addNotificationToUser() {
            try {
                const code = this.$route.query["code"];
                const state = this.$route.query["state"];
                if (state) {
                    const decoded = JSON.parse(atob(state));

                    console.log("decoded:", decoded);

                    const token = await this.$store.dispatch(
                        "lineNotify/getLineNotifyAccessToken",
                        {
                            code,
                            redirect_uri:
                                window.location.origin +
                                window.location.pathname,
                        }
                    );

                    await this.$store.dispatch("alert/createAlert", {
                        userID: decoded.username,
                        name: decoded.name,
                        token,
                        type: AlertTypeLine,
                    });

                    this.$router.replace({
                        name: "ProfileNotifications",
                        params: {
                            username: decoded.username
                        }
                    });
                }
            } catch (error) {
                console.error(error);
            }
        },
    },
};
</script>
