<template>
  <div class="auth-screen tw-flex tw-bg-primary tw-h-screen">
    <component
      class="tw-m-auto"
      :is="authComponent"
      @reset-password="authComponent = 'ResetPassword'"
      @to-signup="authComponent = 'SignUp'"
      @to-signin="authComponent = 'SignIn'"
      @to-confirm-account="onConfirmAccount"
      :user="verifyUser"
    ></component>
  </div>
</template>

<script>
import { onAuthUIStateChange, AuthState } from "@aws-amplify/ui-components";
import Auth from "@aws-amplify/auth";
import Vue from "vue";
import { mapGetters } from "vuex";
import SignIn from "@/components/v2/SignIn.vue";
import SignUp from "@/components/v2/SignUp.vue";
import ResetPassword from "@/components/v2/ResetPassword.vue";
import ConfirmAccount from "@/components/v2/ConfirmAccount.vue";

export default {
  components: {
    SignIn,
    SignUp,
    ResetPassword,
    ConfirmAccount,
  },
  data() {
    return {
      logo: require("@/assets/v2/logo/water_recovered_with_text.png"),
      // formFields: [
      //   {
      //     type: "email",
      //     label: "Email Address *",
      //     placeholder: "Enter your email address",
      //     inputProps: { required: true, autocomplete: "username" },
      //   },
      //   {
      //     type: "password",
      //     label: "Password *",
      //     placeholder: "Enter your password",
      //     inputProps: {
      //       required: true,
      //       autocomplete: "new-password",
      //     },
      //   },
      // ],
      formData: {
        username: "",
        password: "",
      },
      authComponent: "SignIn",
      verifyUser: null,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    signInHeader() {
      return this.$t("SignInHeader");
    },
  },
  // mounted() {
  //   if (this.user) {
  //     this.$router.push({
  //       name: "Profile",
  //       params: {
  //         username: this.user["username"],
  //       },
  //     });
  //   }
  // },
  // created() {
  //   this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
  //     if (authState === AuthState.SignedIn) {
  //       this.$store.commit("auth/setAuthState", authState);
  //       this.$store.commit("auth/setUser", authData);
  //       console.log(authData);
  //       if (authData) {
  //         this.$router.push({
  //           name: "Profile",
  //           params: {
  //             username: authData["username"],
  //           },
  //         });
  //       }
  //     }
  //   });
  // },
  methods: {
    // async onSignIn() {
    //   try {
    //     if (this.formData.username && this.formData.password) {
    //       const user = await Auth.signIn(
    //         this.formData.username,
    //         this.formData.password
    //       );
    //       console.log(user);
    //       if (!user) {
    //         this.$buefy.snackbar.open(`Incorrect username or password.`);
    //         return;
    //       }
    //       this.$router.push({
    //         name: "Profile",
    //         params: {
    //           username: user["username"],
    //         },
    //       });
    //     }
    //   } catch (error) {
    //     console.log("error signing in", error);
    //     const notif = this.$buefy.notification.open({
    //       duration: 5000,
    //       message: error.message,
    //       position: "is-top",
    //       type: "is-danger",
    //     });
    //     if (error.code === "UserNotConfirmedException") {
    //       this.verifyUser = this.formData.username;
    //       this.authComponent = "ConfirmAccount";
    //     }
    //   }
    // },
    async onLineLogin() {
      Auth.federatedSignIn({ customProvider: "LINE" });
    },
    onConfirmAccount(event) {
      console.log("onConfirmAccount", event);
      this.verifyUser = event;
      this.authComponent = "ConfirmAccount";
    },
  },
  beforeDestroy() {
    // this.unsubscribeAuth();
  },
};
</script>

<style scoped>
p {
  color: black;
  padding-bottom: 3px;
}

a {
  color: #1e4b84 !important;
}

.input-box {
  text-align: left;
  width: -webkit-fill-available;
}

.logo {
  border-radius: 999px;
  width: 130px;
  height: 130px;
  padding: 10px;
  position: absolute;
  top: 0%;
  transform: translateY(-50%);
  background: white;
  justify-content: center;
  align-items: center;
  display: flex;
}

.auth-box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 400px;
  height: fit-content;
  padding: 60px 40px 40px 40px;

  background-color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  row-gap: 1rem;

  border-radius: 30px;
}

.notices .snackbar {
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  -webkit-animation-duration: 150ms;
  animation-duration: 150ms;
  margin: 0.5em 0;
  box-shadow: 0 1px 4px rgb(0 0 0 / 12%), 0 0 6px rgb(0 0 0 / 4%);
  border-radius: 4px;
  pointer-events: auto;
  background: white;
  color: #fff;
  min-height: 3em;
}

img {
  height: 100%;
  object-fit: cover;
  max-width: 100%;
}

.controller-box {
  font-size: 13px;
}

>>> .input,
>>> .button {
  border-radius: 10px;
}

>>> .button span {
  width: 100%;
}
</style>
