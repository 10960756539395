<template>
  <div
    class="sign-up tw-bg-white tw-w-96 tw-h-auto tw-rounded-3xl tw-relative tw-mx-auto"
  >
    <div
      class="logo tw-w-32 md tw-h-32 tw-flex tw-items-center tw-bg-white tw-rounded-full tw-absolute tw-left-1/2 tw-transform tw--translate-x-1/2 tw--translate-y-1/2"
    >
      <img :src="logo" class="tw-w-full" />
    </div>
    <div class="tw-mt-14 tw-w-full tw-h-auto tw-p-4 tw-flex tw-flex-col">
      <div class="tw tw-mt-4 tw-text-left">
        <p>อีเมลล์/เบอร์โทรศัพท์:</p>
        <input
          v-model="formData.username"
          class="tw tw-rounded-md tw-w-full tw-py-2 tw-px-2 tw-border"
          type="text"
        />
      </div>
      <div class="tw tw-mt-4 tw-text-left">
        <p>รหัสผ่าน:</p>
        <input
          v-model="formData.password"
          class="tw tw-rounded-md tw-w-full tw-py-2 tw-px-2 tw-border"
          type="password"
        />
      </div>
      <div
        class="tw-text-left tw-flex tw-flex-row tw-justify-between tw-items-center tw-mt-8"
      >
        <span class="">
          มีบัญชีแล้ว?
          <span @click="toSignIn" class="tw-text-primary tw-cursor-pointer"
            >ลงชื่อเข้าใช้</span
          >
        </span>
        <button
          @click="onSignUp"
          class="tw-bg-primary tw-text-white tw-py-2 px-4 tw-rounded-md tw-w-auto"
        >
          สร้างบัญชีผู้ใช้
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Auth from "@aws-amplify/auth";
export default {
  name: "SignUp",
  data() {
    return {
      logo: require("@/assets/v2/logo/water_recovered_with_text.png"),
      formData: {
        username: "",
        password: "",
        code: "",
      },
    };
  },
  methods: {
    async onSignUp() {
      console.log("onSignUp");
      const username = this.formData.username.trim();
      const password = this.formData.password.trim();

      if ([undefined, null, ""].indexOf(username) > 0) {
        console.log("empty username");
        return;
      }
      if ([undefined, null, ""].indexOf(password) > 0) {
        console.log("empty password");
        return;
      }
      try {
        const params = {
          username: username,
          password: password,
        };
        const response = await Auth.signUp(params);
        console.log(response);
        this.onSignIn();
      } catch (error) {
        const notif = this.$buefy.notification.open({
          duration: 5000,
          message: error.message,
          position: "is-top",
          type: "is-danger",
        });
      }
    },
    toSignIn() {
      this.$emit("to-signin");
    },
    async onSignIn() {
      try {
        if (this.formData.username && this.formData.password) {
          const user = await Auth.signIn(
            this.formData.username,
            this.formData.password
          );
          console.log(user);
          if (!user) {
            this.$buefy.snackbar.open(`Incorrect username or password.`);
            return;
          }
          this.$router.push({
            name: "Profile",
            params: {
              username: user["username"],
            },
          });
        }
      } catch (error) {
        console.log("error signing in", error);
        const notif = this.$buefy.notification.open({
          duration: 5000,
          message: error.message,
          position: "is-top",
          type: "is-danger",
        });
        if (error.code === "UserNotConfirmedException") {
          this.$emit("to-confirm-account", {
            username: this.formData.username.trim(),
            password: this.formData.password.trim(),
          });
        }
      }
    },
  },
};
</script>

<style></style>
