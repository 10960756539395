<template>
  <div
    class="sign-in tw-bg-white tw-w-96 tw-h-auto tw-rounded-3xl tw-relative tw-mx-auto"
  >
    <div
      class="logo tw-w-32 md tw-h-32 tw-flex tw-items-center tw-bg-white tw-rounded-full tw-absolute tw-left-1/2 tw-transform tw--translate-x-1/2 tw--translate-y-1/2"
    >
      <img :src="logo" class="tw-w-full" />
    </div>
    <div class="tw-mt-14 tw-w-full tw-h-auto tw-p-4 tw-flex tw-flex-col">
      <button
        @click="onLineLogin"
        class="tw-bg-line tw-px-4 tw-py-2 tw-w-full tw-text-white tw-flex tw-justify-center tw-rounded-md tw-relative"
      >
        <div class="icon tw-absolute tw-left-2">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fab"
            data-icon="line"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            class="svg-inline--fa fa-line fa-w-14 fa-2x"
          >
            <path
              fill="currentColor"
              d="M272.1 204.2v71.1c0 1.8-1.4 3.2-3.2 3.2h-11.4c-1.1 0-2.1-.6-2.6-1.3l-32.6-44v42.2c0 1.8-1.4 3.2-3.2 3.2h-11.4c-1.8 0-3.2-1.4-3.2-3.2v-71.1c0-1.8 1.4-3.2 3.2-3.2H219c1 0 2.1.5 2.6 1.4l32.6 44v-42.2c0-1.8 1.4-3.2 3.2-3.2h11.4c1.8-.1 3.3 1.4 3.3 3.1zm-82-3.2h-11.4c-1.8 0-3.2 1.4-3.2 3.2v71.1c0 1.8 1.4 3.2 3.2 3.2h11.4c1.8 0 3.2-1.4 3.2-3.2v-71.1c0-1.7-1.4-3.2-3.2-3.2zm-27.5 59.6h-31.1v-56.4c0-1.8-1.4-3.2-3.2-3.2h-11.4c-1.8 0-3.2 1.4-3.2 3.2v71.1c0 .9.3 1.6.9 2.2.6.5 1.3.9 2.2.9h45.7c1.8 0 3.2-1.4 3.2-3.2v-11.4c0-1.7-1.4-3.2-3.1-3.2zM332.1 201h-45.7c-1.7 0-3.2 1.4-3.2 3.2v71.1c0 1.7 1.4 3.2 3.2 3.2h45.7c1.8 0 3.2-1.4 3.2-3.2v-11.4c0-1.8-1.4-3.2-3.2-3.2H301v-12h31.1c1.8 0 3.2-1.4 3.2-3.2V234c0-1.8-1.4-3.2-3.2-3.2H301v-12h31.1c1.8 0 3.2-1.4 3.2-3.2v-11.4c-.1-1.7-1.5-3.2-3.2-3.2zM448 113.7V399c-.1 44.8-36.8 81.1-81.7 81H81c-44.8-.1-81.1-36.9-81-81.7V113c.1-44.8 36.9-81.1 81.7-81H367c44.8.1 81.1 36.8 81 81.7zm-61.6 122.6c0-73-73.2-132.4-163.1-132.4-89.9 0-163.1 59.4-163.1 132.4 0 65.4 58 120.2 136.4 130.6 19.1 4.1 16.9 11.1 12.6 36.8-.7 4.1-3.3 16.1 14.1 8.8 17.4-7.3 93.9-55.3 128.2-94.7 23.6-26 34.9-52.3 34.9-81.5z"
              class=""
            ></path>
          </svg>
        </div>
        ล็อกอินด้วย LINE
      </button>
      <form @submit.prevent="onSignIn">
        <div class="tw tw-mt-4 tw-text-left">
          <p>อีเมลล์/เบอร์โทรศัพท์:</p>
          <input
            v-model="formData.username"
            class="tw tw-rounded-md tw-w-full tw-py-2 tw-px-2 tw-border"
            type="text"
          />
        </div>
        <div class="tw tw-mt-4 tw-text-left">
          <p>รหัสผ่าน:</p>
          <input
            v-model="formData.password"
            class="tw tw-rounded-md tw-w-full tw-py-2 tw-px-2 tw-border"
            type="password"
          />
        </div>
        <div class="tw tw-mt-4 tw-text-left tw-text-sm">
          <span>
            ลืมรหัสผ่าน?
            <span class="tw-text-primary tw-cursor-pointer" @click="onResetBtn"
              >รีเซ็ตรหัสผ่าน</span
            >
          </span>
        </div>
        <div
          class="tw-text-left tw-flex tw-flex-row tw-justify-between tw-items-center tw-mt-2"
        >
          <span>
            ไม่มีบัญชีผู้ใช้?
            <span class="tw-text-primary tw-cursor-pointer" @click="onSignUpBtn"
              >สร้างบัญชี</span
            >
          </span>
          <button
            type="submit"
            class="tw-bg-primary tw-text-white tw-p-2 tw-rounded-md tw-w-auto"
          >
            เข้าสู่ระบบ
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Auth from "@aws-amplify/auth";

export default {
  name: "SignIn",
  data() {
    return {
      logo: require("@/assets/v2/logo/water_recovered_with_text.png"),
      formData: {
        username: "",
        password: "",
      },
    };
  },
  methods: {
    async onSignIn() {
      try {
        if (this.formData.username && this.formData.password) {
          const user = await Auth.signIn(
            this.formData.username,
            this.formData.password
          );
          console.log(user);
          if (!user) {
            this.$buefy.snackbar.open(`Incorrect username or password.`);
            return;
          }
          this.$router.push({
            name: "Profile",
            params: {
              username: user["username"],
            },
          });
        }
      } catch (error) {
        console.log("error signing in", error);
        const notif = this.$buefy.notification.open({
          duration: 5000,
          message: error.message,
          position: "is-top",
          type: "is-danger",
        });
        if (error.code === "UserNotConfirmedException") {
          this.$emit("to-confirm-account", {
            username: this.formData.username.trim(),
            password: this.formData.password.trim(),
          });
        }
      }
    },
    async onLineLogin() {
      Auth.federatedSignIn({ customProvider: "LINE" });
    },
    onResetBtn() {
      this.$emit("reset-password");
    },
    onSignUpBtn() {
      this.$emit("to-signup");
    },
  },
};
</script>

<style></style>
