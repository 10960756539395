<template>
  <div
    class="sign-up tw-bg-white tw-w-96 tw-h-auto tw-rounded-3xl tw-relative tw-mx-auto"
  >
    <div
      class="logo tw-w-32 md tw-h-32 tw-flex tw-items-center tw-bg-white tw-rounded-full tw-absolute tw-left-1/2 tw-transform tw--translate-x-1/2 tw--translate-y-1/2"
    >
      <img :src="logo" class="tw-w-full" />
    </div>
    <div
      v-if="!send"
      class="tw-mt-14 tw-w-full tw-h-auto tw-p-4 tw-flex tw-flex-col"
    >
      <p class="tw-text-lg tw-font-bold tw-text-gray-700">รีเซ็ตรหัสผ่าน</p>
      <div class="tw tw-mt-4 tw-text-left">
        <p>อีเมลล์/เบอร์โทรศัพท์:</p>
        <input
          v-model="formData.username"
          class="tw tw-rounded-md tw-w-full tw-py-2 tw-px-2 tw-border"
          type="text"
        />
      </div>
      <div
        class="tw-text-left tw-flex tw-flex-row tw-justify-between tw-items-center tw-mt-8"
      >
        <span @click="onSignIn" class="tw-text-primary tw-cursor-pointer"
          >กลับ</span
        >
        <button
          @click="onSendOTP"
          type="submit"
          class="tw-bg-primary tw-text-white tw-py-2 px-4 tw-rounded-md tw-w-auto"
        >
          ส่งรหัสยืนยัน
        </button>
      </div>
    </div>
    <div v-else class="tw-mt-14 tw-w-full tw-h-auto tw-p-4 tw-flex tw-flex-col">
      <p class="tw-text-lg tw-font-bold tw-text-gray-700">ตั้งรหัสผ่านใหม่</p>
      <div class="tw tw-mt-4 tw-text-left">
        <p>รหัสยืนยัน:</p>
        <input
          v-model="formData.code"
          class="tw tw-rounded-md tw-w-full tw-py-2 tw-px-2 tw-border"
          type="text"
        />
      </div>
      <div class="tw tw-mt-4 tw-text-left">
        <p>รหัสผ่านใหม่:</p>
        <input
          v-model="formData.password"
          class="tw tw-rounded-md tw-w-full tw-py-2 tw-px-2 tw-border"
          type="password"
        />
      </div>
      <!-- <span class=" tw-text-sm tw-text-left tw-mt-2"
        >ไม่ได้รับรหัสยืนยัน?
        <span @click="onResendOTP" class="tw-text-primary tw-cursor-pointer"
          >ส่งรหัสยืนยันอีกครั้ง</span
        ></span
      > -->
      <div
        class="tw-text-left tw-flex tw-flex-row tw-justify-between tw-items-center tw-mt-8"
      >
        <span @click="onSignIn" class="tw-text-primary tw-cursor-pointer"
          >กลับ</span
        >
        <button
          @click="onResetPassword"
          type="submit"
          class="tw-bg-primary tw-text-white tw-py-2 px-4 tw-rounded-md tw-w-auto"
        >
          ยืนยัน
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Auth from "@aws-amplify/auth";
export default {
  name: "ResetPassword",
  data() {
    return {
      logo: require("@/assets/v2/logo/water_recovered_with_text.png"),
      send: false,
      formData: {
        username: "",
        password: "",
        code: "",
      },
    };
  },
  methods: {
    onSignIn() {
      this.$emit("to-signin");
    },
    async onSendOTP() {
      if ([undefined, null, ""].indexOf(this.formData.username) >= 0) {
        console.log("Empty username");
        return;
      }

      try {
        const username = this.formData.username.trim();
        const response = Auth.forgotPassword(username);
        console.log(response);
        this.send = true;
      } catch (error) {
        console.error(error);
      }
    },
    async onResetPassword() {
      if ([undefined, null, ""].indexOf(this.formData.username) >= 0) {
        console.log("Empty username");
        return;
      }
      if ([undefined, null, ""].indexOf(this.formData.password) >= 0) {
        console.log("Empty password");
        return;
      }
      if ([undefined, null, ""].indexOf(this.formData.code) >= 0) {
        console.log("Empty code");
        return;
      }

      try {
        const username = this.formData.username.trim();
        const password = this.formData.password.trim();
        const code = this.formData.code.trim();

        await Auth.forgotPasswordSubmit(username, code, password);
        console.log("Reset password: OK");
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style></style>
